<template>

  <main class="contact">

    <div class="container">
      <div class="row">
        <div class="col s12">

          <router-link to="/" title="Radio Kraków Kultura" class="breadcrumb">
            Radio Kraków Kultura
          </router-link>


          <router-link to="/playlist" title="Kontakt" class="breadcrumb">
            Playlista
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <h1 class="big-title">Playlista</h1>
        </div>
      </div>
      <div class="row" :class="{loading: stateCompare('loading')}">
        <div class="col s12">
          <div class="music_table">
            <div class="music_table_bcg1" v-for="music in musics">
              <div class="timesong">{{ music.time }}</div>
              <div class="break">&nbsp;</div>
              <div>
                <span class="music_table_txt_blue">{{ music.composer }} </span> -
                <span class="song">{{ music.song }}</span>
                <span class="music_table_txt_blue" v-if="music.composer !== music.artist"> - {{ music.artist }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <SectionInfo code="playlist"/>
</template>

<script>
import axios from "@/axios2";
import ComponentListStateMachine from "../Extends/ComponentListStateMachine";
import SectionInfo from "./Home/SectionInfo";

export default {
  name: "Playlist",
  extends: ComponentListStateMachine,
  components: {SectionInfo},
  data() {
    return {
      url: 'https://www3.radiokrakow.pl/rds-off/history.txt',
      musics: [],
      loaded: false,
    }
  },
  created() {

    this.stateUpdate('sendInfo');

    axios
        .get('index/playlistjson')
        .then(response => {

          console.log(response.data)

          this.musics = response.data;

          this.stateUpdate('sendSuccess');

        })
        .catch(e => {
          console.log(e)

          this.stateUpdate('sendWrong');
        })

  }

}
</script>

<style>

.music_table {
  /*width: 600px;*/
  margin: 0 auto;
  position: relative;
}

.music_table_bcg2,
.music_table_bcg1 {
  padding: 12px 12px 12px 72px;

  position: relative;
}

.music_table_bcg1:nth-child(odd) {
  background-color: rgba(0, 0, 0, .05);
}

.music_table_bcg1 {
}

.music_table_bcg2 .break,
.music_table_bcg1 .break {
  display: none;
}

.music_table > :first-child {
  /*background: white;*/
  /*color: #000;*/

  /*padding-left: 48px;*/
}

.music_table .timesong {
  position: absolute;
  left: 12px;
  top: 12px;
}

.music_table .song {
  font-style: italic;
}


.music_table > div > div:nth-child(3) {
  display: inline-block;
}

.music_table > :first-child:after {

  content: '';
  background-color: red;
  /*position: absolute;*/
  /*right: 22px;*/
  /*top: 20px;*/
  box-shadow: 0 0 0 0 rgb(255 0 0);
  transform: scale(1);
  animation: pulse 2s infinite;
  width: 8px;
  height: 8px;
  border-radius: 50%;

  position: relative;

  z-index: 4;

  display: inline-block;

  /*float: left;*/

  margin-left: 12px;
  margin-top: -2px;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}


</style>